import { FilterBy } from '@1po/1po-bff-fe-spec/generated/common/filter_and_sort/FilterBy';
import {
  CHECKOUT_ORDER_RESPONSE,
  GET_EXTERNAL_ORDER_LIST_RESPONSE,
  GET_ORDER_BY_ID_RESPONSE,
  GET_ORDER_LIST_RESPONSE,
  IS_RETURNS_FEATURE_ENABLED_RESPONSE,
  RE_ADD_ORDERED_REFERENCES_TO_BASKET_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import {
  CheckoutExternalBasketReference,
  CheckoutOtherSection,
  CheckoutVehicleReference,
} from '@1po/1po-bff-fe-spec/generated/order/request/CheckoutOrderRequest';
import { FilterAndSortField } from '@1po/1po-bff-fe-spec/generated/order/request/model/FilterAndSortField';
import {
  CheckoutOverallStatus,
  OrderCheckoutResult,
} from '@1po/1po-bff-fe-spec/generated/order/response/CheckoutOrderResponse';
import { OrderDeliveryType, OrderItem } from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import { ExternalOrderItem } from '@1po/1po-bff-fe-spec/generated/order/response/model/ExternalOrderItem';
import { ReferenceToReturn } from '@1po/1po-bff-fe-spec/generated/order/response/ReturnEmailResponse';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import {
  CHECKOUT_ORDER_REQUEST,
  FilterKey,
  GET_EXTERNAL_ORDER_LIST_REQUEST,
  GET_IS_RETURNS_FEATURE_ENABLED_BY_SELLER_ID_REQUEST,
  GET_ORDER_BY_ID_REQUEST,
  GET_ORDER_LIST_EXPORT_REQUEST,
  GET_ORDER_LIST_REQUEST,
  ORDER_NAMESPACE,
  OrderItemLocal,
  OrderReferenceItemLocal,
  OrderReturnItem,
  OrderState,
  RE_ADD_ORDER_REFERENCES_TO_BASKET,
  SortKey,
  SortOption,
} from 'domains/order/Order.types';
import { ERROR, FOUND, LOADING, NO_DATA, NOT_FOUND, SearchData } from 'utils';

// Init state
const initialState: OrderState = {
  checkoutStatus: null,
  allowedReturnsFeatures: new Map<string, boolean>(),
  inProgressOrders: {
    orderMap: new Map<string, SearchData<OrderItemLocal>>(),
    orderIds: {
      searchStatus: undefined,
    },
    isLoading: false,
    removeOldData: false,
    cursor: undefined,
    hasMore: true,
    sort: undefined,
    filters: new Map<SortKey, FilterBy<FilterAndSortField>>(),
    activeFilters: new Map<FilterKey, string[]>(),
    pendingOrderIdsToReAddToBasket: [],
  },
  externalOrders: {
    orders: undefined,
  },
};

// Saga actions
export const checkoutOrderRequestSaga = createAction<{
  referencesByVehicles: CheckoutVehicleReference[];
  referencesByExternalBasket: CheckoutExternalBasketReference[];
  checkoutOtherSection: CheckoutOtherSection;
  deliveryType: OrderDeliveryType;
  paymentClientCode: string;
}>(CHECKOUT_ORDER_REQUEST);
export const checkoutOrderResponseSaga = createAction(CHECKOUT_ORDER_RESPONSE);
export const fetchDealerOrdersRequestSaga = createAction(GET_ORDER_LIST_REQUEST);
export const downloadDealerOrdersRequestSaga = createAction(GET_ORDER_LIST_EXPORT_REQUEST);
export const fetchDealerOrdersResponseSaga = createAction(GET_ORDER_LIST_RESPONSE);
export const fetchOrderByIdRequestSaga = createAction<string>(GET_ORDER_BY_ID_REQUEST);
export const fetchIsReturnsFeatureEnabledForSellerRequestSaga = createAction<string>(
  GET_IS_RETURNS_FEATURE_ENABLED_BY_SELLER_ID_REQUEST,
);
export const fetchIsReturnsFeatureEnabledForSellerResponseSaga = createAction(IS_RETURNS_FEATURE_ENABLED_RESPONSE);
export const fetchOrderByIdResponseSaga = createAction(GET_ORDER_BY_ID_RESPONSE);
export const reAddOrderReferencesToBasketRequestSaga = createAction<string>(RE_ADD_ORDER_REFERENCES_TO_BASKET);
export const reAddOrderReferencesToBasketResponseSaga = createAction(RE_ADD_ORDERED_REFERENCES_TO_BASKET_RESPONSE);
export const fetchDealerExternalOrdersRequestSaga = createAction(GET_EXTERNAL_ORDER_LIST_REQUEST);
export const fetchDealerExternalOrdersResponseSaga = createAction(GET_EXTERNAL_ORDER_LIST_RESPONSE);

const mapToLocalOrder = (orderItem: OrderItem): OrderItemLocal => {
  return {
    ...orderItem,
    orderedReferences: orderItem.orderedReferences.map((orderRef) => ({
      ...orderRef,
      requestReason: undefined,
      qtyToReturn: 0,
      substitutes: orderRef.substitutes?.map((substituteRef) => ({
        ...substituteRef,
        requestReason: undefined,
        qtyToReturn: 0,
      })),
    })),
  };
};

const slice = createSlice({
  name: ORDER_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setCheckoutStatus: (
      state,
      action: PayloadAction<{
        placedOrders: OrderCheckoutResult[];
        failedOrders: OrderCheckoutResult[];
        ordersOverallStatus: CheckoutOverallStatus;
      }>,
    ) => {
      const { placedOrders, failedOrders, ordersOverallStatus } = action.payload;
      state.checkoutStatus = {
        placedOrders,
        failedOrders,
        ordersOverallStatus,
      };
    },
    clearCheckoutStatus: (state) => {
      state.checkoutStatus = null;
    },
    setInProgressOrdersNotFound: (state, { payload }: PayloadAction<typeof ERROR | typeof NOT_FOUND | undefined>) => {
      state.inProgressOrders.orderIds = {
        searchStatus: payload,
        data: undefined,
      };
      state.inProgressOrders.hasMore = false;
      state.inProgressOrders.isLoading = false;
    },
    addInProgressOrdersData: (
      state,
      action: PayloadAction<{ orderItems: OrderItem[]; cursor: string; hasMore: boolean }>,
    ) => {
      const { orderItems, cursor, hasMore } = action.payload;

      const currOrderIds = state.inProgressOrders.removeOldData ? [] : state.inProgressOrders.orderIds?.data ?? [];
      orderItems.forEach((o) => {
        state.inProgressOrders.orderMap.set(o.internalOrderId, { searchStatus: FOUND, data: mapToLocalOrder(o) });
      });
      state.inProgressOrders.orderIds = {
        searchStatus: FOUND,
        data: currOrderIds.concat(orderItems.map((o) => o.internalOrderId)),
      };
      state.inProgressOrders.hasMore = hasMore;
      state.inProgressOrders.cursor = cursor;
      state.inProgressOrders.removeOldData = false;
    },
    addInProgressOrder: (state, action: PayloadAction<OrderItem>) => {
      const orderItem = action.payload;
      state.inProgressOrders.orderMap.set(orderItem.internalOrderId, {
        searchStatus: FOUND,
        data: mapToLocalOrder(orderItem),
      });
    },
    addOrderIdToPendingAddToBasketRequests: (state, action: PayloadAction<string>) => {
      const orderId = action.payload;
      state.inProgressOrders.pendingOrderIdsToReAddToBasket = [
        ...state.inProgressOrders.pendingOrderIdsToReAddToBasket,
        orderId,
      ];
    },
    removeOrderIdFromPendingAddToBasketRequests: (state, action: PayloadAction<string>) => {
      const orderId = action.payload;
      state.inProgressOrders.pendingOrderIdsToReAddToBasket = state.inProgressOrders.pendingOrderIdsToReAddToBasket.filter(
        (id) => id !== orderId,
      );
    },
    setInProgressOrderSearchStatus: (
      state,
      action: PayloadAction<{ orderId: string; status: typeof LOADING | typeof NOT_FOUND | typeof ERROR }>,
    ) => {
      const { orderId, status } = action.payload;
      state.inProgressOrders.orderMap.set(orderId, {
        searchStatus: status,
      });
    },
    resetInProgressOrdersForNewSearch: (state) => {
      state.inProgressOrders.orderIds = {
        searchStatus: undefined,
        data: undefined,
      };
      state.inProgressOrders.removeOldData = true;
      state.inProgressOrders.hasMore = true;
      state.inProgressOrders.cursor = undefined;
    },
    setActiveFilters: (state) => {
      const startDate = state.inProgressOrders.filters.get('START_DATE');
      const endDate = state.inProgressOrders.filters.get('END_DATE');
      [...state.inProgressOrders.filters.entries()]
        .filter(([key, _]) => key !== 'START_DATE' && key !== 'END_DATE')
        .forEach(([key, value]) => {
          state.inProgressOrders.activeFilters.set(key as FilterKey, [value.value]);
        });
      if (startDate && endDate) {
        state.inProgressOrders.activeFilters.set('DATE', [startDate.value, endDate.value]);
        return;
      }
    },
    setInProgressOrdersFilter: (
      state,
      { payload }: PayloadAction<{ filter: FilterBy<FilterAndSortField>; key: SortKey }>,
    ) => {
      state.inProgressOrders.filters.set(payload.key, payload.filter);
    },
    resetInProgressOrdersFilter: (state, { payload }: PayloadAction<{ key: FilterKey; statusValue?: string }>) => {
      if (payload.key === 'STATUS') {
        const status = state.inProgressOrders.filters.get('STATUS');
        if (!status || !payload.statusValue) {
          return;
        }

        const statusArray = status.value.split('-').filter((elem) => elem !== payload.statusValue);

        const newStatusValue = statusArray.join('-');
        const modifiedStatus: FilterBy<FilterAndSortField> = new FilterBy<FilterAndSortField>(
          'STATUS',
          newStatusValue,
          'IN',
        );

        state.inProgressOrders.filters.set('STATUS', modifiedStatus);
        state.inProgressOrders.activeFilters.set('STATUS', [newStatusValue]);
        return;
      }
      state.inProgressOrders.activeFilters.delete(payload.key);
      if (payload.key === 'DATE') {
        state.inProgressOrders.filters.delete('START_DATE');
        state.inProgressOrders.filters.delete('END_DATE');
        return;
      }
      state.inProgressOrders.filters.delete(payload.key);
    },
    resetInProgressOrdersFilters: (state) => {
      state.inProgressOrders.filters.clear();
      state.inProgressOrders.activeFilters.clear();
    },
    setInProgressOrdersSort: (state, { payload }: PayloadAction<SortOption>) => {
      state.inProgressOrders.sort = payload;
    },
    setInProgressIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.inProgressOrders.isLoading = payload;
    },
    setExternalOrdersNoDataStatus: (state, { payload }: PayloadAction<NO_DATA>) => {
      state.externalOrders.orders = payload;
    },
    addExternalOrdersData: (state, action: PayloadAction<ExternalOrderItem[]>) => {
      state.externalOrders.orders = action.payload;
    },
    resetReturnRequest: (state, { payload }: PayloadAction<string>) => {
      const order = state.inProgressOrders.orderMap.get(payload)?.data;
      if (order === undefined) {
        return;
      }

      const resetReferences = order.orderedReferences.map((ref) => ({
        ...ref,
        substitutes: ref.substitutes?.map((substitutedRef) => ({
          ...substitutedRef,
          requestReason: undefined,
          qtyToReturn: 0,
        })),
        requestReason: undefined,
        qtyToReturn: 0,
      }));

      state.inProgressOrders.orderMap.set(order.internalOrderId, {
        searchStatus: FOUND,
        data: { ...order, orderedReferences: resetReferences },
      });
    },
    updateReturnRequest: (
      state,
      { payload }: PayloadAction<{ index: number; orderId: string; reference: OrderReferenceItemLocal }>,
    ) => {
      const { index, orderId, reference } = payload;

      const order = state.inProgressOrders.orderMap.get(orderId)?.data;
      if (order === undefined) {
        return;
      }

      const updatedReferences = [...order.orderedReferences];
      updatedReferences[index] = reference;

      state.inProgressOrders.orderMap.set(order.internalOrderId, {
        searchStatus: FOUND,
        data: { ...order, orderedReferences: updatedReferences },
      });
    },
    updateReturnedReferenceQty: (
      state,
      { payload }: PayloadAction<{ orderId: string; references: ReferenceToReturn[] }>,
    ) => {
      const { orderId, references } = payload;

      const order = state.inProgressOrders.orderMap.get(orderId)?.data;
      if (order === undefined) {
        return;
      }

      const updatedReferences = [...order.orderedReferences];

      references.forEach((ref) => {
        if (!ref.parentReference) {
          const index = updatedReferences.findIndex((r) => r.referenceNumber === ref.referenceNumber);
          if (index !== -1) {
            updatedReferences[index] = {
              ...updatedReferences[index],
              returnedQuantity: (updatedReferences[index].returnedQuantity ?? 0) + (ref.returnedQuantity ?? 0),
            };
          }
          return;
        }

        const parentIndex = updatedReferences.findIndex((r) => r.referenceNumber === ref.parentReference);
        if (parentIndex !== -1) {
          const parentRef = updatedReferences[parentIndex];

          if (!parentRef || !parentRef.substitutes) {
            return;
          }
          const substitutedIndex = parentRef.substitutes.findIndex((r) => r.referenceNumber === ref.referenceNumber);
          if (substitutedIndex !== -1) {
            parentRef.substitutes[substitutedIndex] = {
              ...parentRef.substitutes[substitutedIndex],
              returnedQuantity:
                (parentRef.substitutes[substitutedIndex].returnedQuantity ?? 0) + (ref.returnedQuantity ?? 0),
            };
          }
        }
      });
      state.inProgressOrders.orderMap.set(order.internalOrderId, {
        searchStatus: FOUND,
        data: { ...order, orderedReferences: updatedReferences },
      });
    },
    cacheEnabledReturnsFeatures: (state, action: PayloadAction<{ r1code: string; isEnabled: boolean }>) => {
      const { r1code, isEnabled } = action.payload;
      state.allowedReturnsFeatures.set(r1code, isEnabled);
    },
  },
});

// Actions
export const {
  setInitialState,
  setCheckoutStatus,
  clearCheckoutStatus,
  setInProgressOrdersNotFound,
  addInProgressOrdersData,
  addInProgressOrder,
  setInProgressOrderSearchStatus,
  resetInProgressOrdersForNewSearch,
  setActiveFilters,
  setInProgressOrdersFilter,
  resetInProgressOrdersFilter,
  resetInProgressOrdersFilters,
  setInProgressOrdersSort,
  setInProgressIsLoading,
  addExternalOrdersData,
  setExternalOrdersNoDataStatus,
  resetReturnRequest,
  updateReturnRequest,
  updateReturnedReferenceQty,
  addOrderIdToPendingAddToBasketRequests,
  removeOrderIdFromPendingAddToBasketRequests,
  cacheEnabledReturnsFeatures,
} = slice.actions;

// Getters/Selectors
export const getCheckoutStatus = createSelector(
  (state: RootState) => state.order.checkoutStatus,
  (checkoutStatus) => checkoutStatus,
);

export const getInProgressOrderIdsData = createSelector(
  (state: RootState) => state.order.inProgressOrders.orderIds,
  (orders) => orders?.data ?? [],
);

export const getInProgressOrder = createSelector(
  (state: RootState) => state.order.inProgressOrders.orderMap,
  (_state: RootState, orderId: string) => orderId,
  (orderMap, orderId) => orderMap.get(orderId),
);

export const getInProgressOrders = createSelector(
  (state: RootState) => state.order.inProgressOrders.orderMap,
  (_state: RootState, orderIds: string[]) => orderIds,
  (orderMap, orderIds) =>
    orderIds.map((orderId) => orderMap.get(orderId)?.data).filter((o): o is OrderItemLocal => o !== undefined),
);

export const getInProgressOrdersCursor = createSelector(
  (state: RootState) => state.order.inProgressOrders.cursor,
  (cursor) => cursor,
);

export const getInProgressOrdersHasMore = createSelector(
  (state: RootState) => state.order.inProgressOrders.hasMore,
  (hasMore) => hasMore,
);

export const getAllInProgressOrdersFilters = createSelector(
  (state: RootState) => state.order.inProgressOrders.filters,
  (filters) => [...filters.values()].filter((f) => f.value.length !== 0),
);

export const getActiveFilters = createSelector(
  (state: RootState) => state.order.inProgressOrders.activeFilters,
  (filters) => Array.from(filters).filter(([_, value]) => value.length !== 0 && value[0].length !== 0),
);

export const getInProgressOrdersIsLoading = createSelector(
  (state: RootState) => state.order.inProgressOrders.isLoading,
  (isLoading) => isLoading,
);

export const getInProgressOrdersFilter = createSelector(
  (state: RootState) => state.order.inProgressOrders.filters,
  (_state: RootState, field: SortKey) => field,
  (filters, field) => filters.get(field),
);

export const getInProgressOrdersSort = createSelector(
  (state: RootState) => state.order.inProgressOrders.sort,
  (sort) => sort,
);

export const getExternalOrders = createSelector(
  (state: RootState) => state.order.externalOrders.orders,
  (orders) => orders,
);

export const isReturnsFeatureEnabledForSeller = createSelector(
  (state: RootState, sellerId: string) => sellerId,
  (state: RootState) => state.order.allowedReturnsFeatures,
  (sellerId, allowedReturnsFeatures) => {
    return allowedReturnsFeatures.get(sellerId);
  },
);

export const getReferencesToReturn = createSelector(
  (state: RootState) => state.order.inProgressOrders.orderMap,
  (_state: RootState, orderId: string) => orderId,
  (orderMap, orderId) => {
    const order = orderMap.get(orderId)?.data;
    if (order === undefined) {
      return;
    }

    const referencesToReturn: OrderReturnItem[] = [];

    order.orderedReferences.forEach((orderedRef, index) => {
      if (orderedRef.requestReason && orderedRef.qtyToReturn) {
        referencesToReturn.push({
          index,
          referenceNumber: orderedRef.referenceNumber,
          name: orderedRef.name,
          brand: orderedRef.brand,
          qtyToReturn: orderedRef.qtyToReturn,
          supplier: orderedRef.supplier,
          requestReason: orderedRef.requestReason,
        });
      }
      orderedRef.substitutes?.forEach((substitutedRef, index) => {
        if (substitutedRef.requestReason && substitutedRef.qtyToReturn) {
          referencesToReturn.push({
            index,
            referenceNumber: substitutedRef.referenceNumber,
            parentReferenceNumber: orderedRef.referenceNumber,
            name: undefined,
            qtyToReturn: substitutedRef.qtyToReturn,
            brand: undefined,
            supplier: undefined,
            requestReason: substitutedRef.requestReason,
          });
        }
      });
    });
    return referencesToReturn;
  },
);

export const isOrderIdInPendingReAddToBasketRequests = createSelector(
  (state: RootState) => state.order.inProgressOrders.pendingOrderIdsToReAddToBasket,
  (_state: RootState, orderId: string) => orderId,
  (array, orderId) => array.includes(orderId),
);

// Export reducer
export default slice.reducer;
