/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { WrenchIcon } from 'assets/icons';
import { ClarionLink } from 'components/ClarionLink/ClarionLink';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { getElectronicPartsRepairLink } from 'domains/appContext/AppContext.store';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { getRepairPrice } from 'domains/references';
import { useFetchRepairPrices } from 'domains/references/References.requests';
import { getCurrency } from 'domains/user';
import { theme, ThemeFontKeyType } from 'styles';
import { Box, Flex, Icon, Pipeline, Text } from 'UI';
import { textFormatter } from 'utils';

const ElectronicPartsRepairLink = ({ referenceNumber, type }: { referenceNumber: string; type: ThemeFontKeyType }) => {
  const { t } = useTranslation();
  const repairPrice = useSelector((state: RootState) => getRepairPrice(state, referenceNumber));
  const repairPriceData = repairPrice?.data;
  const currency = useSelector(getCurrency);
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const repairLink = useSelector(getElectronicPartsRepairLink);

  useFetchRepairPrices([referenceNumber], vehicleKey);

  if (isNaN(Number(repairPrice?.data))) {
    return null;
  }

  return (
    <DataContainer
      data={repairPrice?.searchStatus}
      NotFound={() => <></>}
      ErrorState={() => (
        <ErrorWithLabel
          label={t(
            'common.repair_price.backend_error',
            'Repair price temporarily unavailable, please try again later.',
          )}
          narrow
        />
      )}
    >
      {repairLink && repairPriceData && (
        <>
          <Box width={9} />
          <Pipeline size={30} offsetRatio={9} color={theme.color.grey_06} />
          <Flex>
            <Icon
              IconComponent={WrenchIcon}
              size={16}
              mr={5}
              mt={2}
              display={'block'}
              color={theme.color.grey55}
              noPointer
            />
            <ClarionLink
              renderLinkComponent={() => (
                <Text hoverUnderLine type={type} displayStyle={'link'} cursor={'pointer'}>
                  {t('catalog.dh.reference_card.action.repair', 'Repair at: {{repairPrice}}', {
                    repairPrice: textFormatter.formatCurrency(repairPriceData, currency),
                  })}
                </Text>
              )}
              renderLoadingComponent={() => (
                <Text hoverUnderLine type={type} displayStyle={'link'} cursor={'pointer'}>
                  {t('link.redirecting_in_progress', 'Redirecting...')}
                </Text>
              )}
            />
          </Flex>
        </>
      )}
    </DataContainer>
  );
};

export default ElectronicPartsRepairLink;
