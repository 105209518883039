import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import styled from 'styled-components';
import { ROUTER_HOME, ROUTER_TIRES, ROUTER_UNIVERSAL_PRODUCTS } from 'app/AppRouter';
import { ClarionLink } from 'components/ClarionLink/ClarionLink';
import { useAutomaticFirstHelp } from 'domains/firstHelp/FirstHelp.requests';
import { getPromotionBanners } from 'domains/promotion/Promotion.store';
import { PromotionBannerCarousel } from 'domains/promotion/Promotion.types';
import { getUserCountry, getVehicleSearchHistory } from 'domains/user';
import { HomepageLg } from 'pages/HomePage/HomepageLg';
import { HomepageSm } from 'pages/HomePage/HomepageSm';
import { Box, Flex, FlexJustifyType, Link, PUBLIC, Text } from 'UI';
import { notifyNotImplemented } from 'UI/Notification/notification';
import { getData, hasData, useSmall } from 'utils';
import { useBreakpointSelector, useBreakpointSelectorFull } from 'utils/hooks/useBreakpoint';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { useView } from 'utils/hooks/useView';
import { Country } from 'utils/i18n/Country';

const SFlex = styled(Flex)`
  cursor: pointer;
`;

export const getPortfolioData = (small: boolean, showTires: boolean, t: TFunction): Array<PortfolioCardProps> => {
  const tires = {
    bg: 'home/tires.jpg',
    content: (
      <Link to={ROUTER_TIRES}>
        {renderCategoryDescription(
          t('common.link.learn_more', 'Learn more'),
          HeaderColor.WHITE,
          t('catalog.tires', 'Tires'),
          getDescriptionFont(true),
          t('catalog.tires.description', 'Feel the road as you never did before'),
        )}
      </Link>
    ),
    dark: true,
  };

  const universalProducts = {
    bg: 'home/universal-products.jpg',
    content: (
      <Link to={ROUTER_UNIVERSAL_PRODUCTS}>
        {renderCategoryDescription(
          t('common.link.learn_more', 'Learn more'),
          HeaderColor.BLACK,
          t('catalog.universal_products', 'Universal products'),
          getDescriptionFont(false),
          t('catalog.universal_products.description', 'Find Tuning & Styling products'),
        )}
      </Link>
    ),
    dark: false,
  };

  const accessories = {
    bg: 'home/accessories.jpg',
    content: (
      <Box onClick={notifyNotImplemented}>
        {renderCategoryDescription(
          t('common.link.learn_more', 'Learn more'),
          HeaderColor.BLACK,
          t('catalog.merchandizing', 'Merchandizing'),
          getDescriptionFont(false),
          t('catalog.merchandizing.description', 'Explore the Huge Range of Car Merchandizing'),
        )}
      </Box>
    ),
    dark: false,
  };

  const electronicPieces = {
    bg: 'home/electronic-pieces.jpg',
    content: (
      <ClarionLink
        renderLinkComponent={() =>
          renderCategoryDescription(
            t('common.link.learn_more', 'Learn more'),
            HeaderColor.WHITE,
            t('catalog.electronic_pieces', 'Electronic pieces'),
            getDescriptionFont(true),
            t('catalog.electronic_pieces.description', 'Looking for a catch line'),
          )
        }
        renderLoadingComponent={() =>
          renderCategoryDescription(
            t('link.redirecting_in_progress', 'Redirecting...'),
            HeaderColor.WHITE,
            t('catalog.electronic_pieces', 'Electronic pieces'),
            getDescriptionFont(true),
            t('catalog.electronic_pieces.description', 'Looking for a catch line'),
          )
        }
      />
    ),
    dark: true,
  };

  return small
    ? ([showTires && tires, universalProducts, electronicPieces, accessories].filter(
        Boolean,
      ) as Array<PortfolioCardProps>)
    : ([showTires && tires, universalProducts, accessories, electronicPieces].filter(
        Boolean,
      ) as Array<PortfolioCardProps>);
};

enum HeaderColor {
  BLACK = 'black',
  WHITE = 'white',
}

const headerColors: Record<HeaderColor, string> = {
  [HeaderColor.BLACK]: 'h1_lead',
  [HeaderColor.WHITE]: 'h1_lead_white',
};

const getDescriptionFont = (dark: boolean) => (dark ? 'lead_white' : 'lead_dim');

const renderCategoryDescription = (
  text: string,
  headerColor: HeaderColor,
  title: ReactNode,
  descriptionFont: string,
  description: ReactNode,
) => (
  <Box width={'100%'} height={'100%'}>
    <Box height={30} />
    <Text type={headerColors[headerColor]} cursor={'pointer'}>
      {title}
    </Text>
    <Text cursor={'pointer'} type={descriptionFont}>
      {description}
    </Text>
    <br />
    <Text type={'h5_light'} displayStyle={'link'} cursor={'pointer'} hoverUnderLine>
      {text}
    </Text>
  </Box>
);

export interface PortfolioCardProps {
  bg: string;
  content: ReactNode;
  index: number;
  dark?: boolean;
}

export const PortfolioCard = ({ bg, content, dark, index }: PortfolioCardProps) => {
  const breakpointSelector = useBreakpointSelector();
  const small = useSmall();

  const justify = breakpointSelector(['center', index % 2 === 0 ? 'flex-end' : 'flex-start']);
  const background = dark ? 'black' : 'white';

  const fullBreakpointSelector = useBreakpointSelectorFull();
  const width = fullBreakpointSelector({
    xs: 0,
    sm: 691,
    md: 691,
    lg: 691,
    xl: 691,
    xxl: 691 * 2,
  });
  const height = fullBreakpointSelector({
    xs: 0,
    sm: 389,
    md: 389,
    lg: 389,
    xl: 389,
    xxl: 389 * 1.35,
  });

  return (
    <SFlex justify={justify as FlexJustifyType}>
      <Box
        width={width}
        height={small ? 150 : height}
        backgroundImage={bg}
        backgroundImageType={PUBLIC}
        background={background}
        align={'center'}
      >
        {content}
      </Box>
    </SFlex>
  );
};

const HomePage = (): JSX.Element => {
  const small = useSmall();
  const promotionBanners = useSelector(getPromotionBanners);
  const banners: PromotionBannerCarousel[] = useMemo(() => [...(getData(promotionBanners) ?? [])], [promotionBanners]);
  const bannersWithImage = useMemo(() => (hasData(banners) ? banners.filter((banner) => banner.showInCarousel) : []), [
    banners,
  ]);
  const { t } = useTranslation();
  const vehicleSearchHistory = useSelector(getVehicleSearchHistory);
  const country = useSelector(getUserCountry);
  const showTires = Country.canShowTiresByCountry(country);
  const portfolioData = getPortfolioData(small, showTires, t);

  useResetScroll();
  useAutomaticFirstHelp(ROUTER_HOME, false);

  return useView([HomepageSm, HomepageLg, HomepageLg], {
    vehicleSearchHistory,
    banners,
    bannersWithImage,
    portfolioData,
  });
};
export default HomePage;
