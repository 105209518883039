/* eslint-disable max-len */
import { CHECKOUT_ORDER_RESPONSE } from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import {
  CHECKOUT_ORDER_URL,
  EXTERNAL_ORDER_LIST_GET_URL,
  ORDER_GET_BY_ID_URL,
  ORDER_IS_RETURNS_FEATURE_ENABLED_URL,
  ORDER_LIST_DOWNLOAD_URL,
  ORDER_LIST_GET_URL,
  ORDER_RE_ADD_REFERENCES_TO_BASKET,
} from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { CheckoutOrderRequest } from '@1po/1po-bff-fe-spec/generated/order/request/CheckoutOrderRequest';
import { DownloadDealerDeliveryTracksRequest } from '@1po/1po-bff-fe-spec/generated/order/request/DownloadDealerDeliveryTracksRequest';
import { GetDealerOrderPageRequest } from '@1po/1po-bff-fe-spec/generated/order/request/GetDealerOrderPageRequest';
import { GetOrderByIdRequest } from '@1po/1po-bff-fe-spec/generated/order/request/GetOrderByIdRequest';
import { IsReturnsFeatureEnabledRequest } from '@1po/1po-bff-fe-spec/generated/order/request/IsReturnsFeatureEnabledRequest';
import { ReAddOrderReferencesToBasketRequest } from '@1po/1po-bff-fe-spec/generated/order/request/ReAddOrderReferencesToBasketRequest';
import { RequestResponseMappingManager } from 'domains/webSockets/WebSocket.requestsResponseMapping';
import { TWENTY_SEC, WebSocketAction } from 'utils/domainStore';
import { wsSendAction } from 'utils/domainStore/api';

RequestResponseMappingManager.addMapping(CHECKOUT_ORDER_URL, CHECKOUT_ORDER_RESPONSE);

export function sendCheckoutOrderRequest(request: CheckoutOrderRequest): WebSocketAction {
  // TWENTY_SEC as timeout, as there are multiple API calls to be performed
  return wsSendAction(CHECKOUT_ORDER_URL, request, TWENTY_SEC);
}

export function sendOrderListRequest(request: GetDealerOrderPageRequest): WebSocketAction {
  return wsSendAction(ORDER_LIST_GET_URL, request);
}

export function sendDownloadDealerDeliveryTracksRequest(request: DownloadDealerDeliveryTracksRequest): WebSocketAction {
  return wsSendAction(ORDER_LIST_DOWNLOAD_URL, request);
}

export function sendExternalOrderListRequest(): WebSocketAction {
  return wsSendAction(EXTERNAL_ORDER_LIST_GET_URL, null);
}

export function sendGetOrderById(request: GetOrderByIdRequest): WebSocketAction {
  return wsSendAction(ORDER_GET_BY_ID_URL, request);
}

export function sendReAddReferencesToBasket(request: ReAddOrderReferencesToBasketRequest): WebSocketAction {
  return wsSendAction(ORDER_RE_ADD_REFERENCES_TO_BASKET, request);
}

export function sendGetReturnsFeatureEnabledBySellerId(request: IsReturnsFeatureEnabledRequest): WebSocketAction {
  return wsSendAction(ORDER_IS_RETURNS_FEATURE_ENABLED_URL, request);
}
